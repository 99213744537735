<!--
    Created by 程雨喵'mac on 2024/5/10.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：订单流水对比
-->
<style lang="less">
.analysis-order-data {
  margin-top: @containerGap;
  height: @containerGap * 7 + 560px;
  padding: @containerGap;
  background-color: @backColorStrong;
  border-radius: @borderRadiusMin;
  box-shadow: @shadowMin;
  .echarts-box {
    display: flex;
    .chart-item {
      flex: 1;
    }
  }
  .top-oder-box {
    margin-top: @containerGap * 0.5;
    margin-bottom: @containerGap;
    font-size: 12px;
    color: @textColorLight;
    .order-total {
      text-align: center;
    }
    .order-percent-outer {
      margin: @containerGap * 0.5 0;
      padding: 3px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      background-color: @backColorNormal;
      border-radius: @borderRadiusMin;
      .square {
        position: relative;
        width: 4%; /* 设置元素宽度为50% */
        height: 20px;
      }

      .square::before {
        //content: "";
        //display: block;
        //padding-top: 100%; /* 设置伪元素的内边距为100%，使其宽高相等 */
      }
      .square-inner {
        position: absolute;
        top: 3px;
        bottom: 3px;
        left: 3px;
        right: 3px;
        border-radius: @borderRadiusMin;
        background-color: @finishColor;
        &.is-valid-color {
          background-color: @themeColor;
        }
      }
    }
    .sub-percent {
      display: flex;
      justify-content: space-between;
      .percent-value {
        color: @textColorLight;
        span {
          color: @textColorNormal;
          font-weight: 600;
        }
      }
    }
  }
  .echarts-box-gap {
    margin-top: @containerGap;
    padding-top: @containerGap;
    border-top: 1px solid @lineColorLight;
  }
}
</style>

<template>
  <div class="analysis-order-data">
    <div class="t1">
      订单分析
    </div>
    <div
      class="top-oder-box"
    >
      <div class="order-total">
        支付订单总数：{{ boxData.orderAmount }}
      </div>
      <div class="order-percent-outer">
        <div
          v-for="(item, i) in dotItems"
          :key="'num-' + i"
          class="square"
        >
          <div
            class="square-inner"
            :class="item === 1 ? 'is-valid-color' : ''"
          />
        </div>
      </div>
      <div class="sub-percent">
        <div class="percent-value">
          有效订单：<span>{{ boxData.validOrderAmount }}</span> (占比: <span>{{ boxData.validOrderPercent }} %</span>)
        </div>
        <div class="percent-value">
          无效订单：<span>{{ boxData.disabledOrderAmount }}</span> (占比: <span>{{ boxData.disabledOrderPercent }} %</span>)
        </div>
      </div>
    </div>
    <div class="echarts-box">
      <div
        id="orderLineDiv"
        style="width:100%;height:220px"
        class="line-chart"
      />
    </div>
    <div class="echarts-box-gap">
      <div class="t1">
        有效订单分布
      </div>
      <div
        id="orderPieDiv1"
        style="width:100%;height:200px"
      />
    </div>
  </div>
</template>

<script>
import { DATA_COLOR } from '../utils/colors';
import { sortBy } from '@/libs/utils';
import { safeToFloat } from '@/libs/safe';
import box from '../../../../components/common/reactive/box.vue';
const echarts = require('echarts');

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      boxData: {
        orderAmount: 0,
        validOrderAmount: 0,
        disabledOrderAmount: 0,
        disabledOrderPercent: 0,
        validOrderPercent: 0,
        validOrderPercentCeil: 0
      },
      dotItems: []
    };
  },
  computed: {
    box () {
      return box;
    }
  },
  mounted () {
    const b = this.boxData;
    const m = this.model.totalAmountModel;
    b.orderAmount = m.orderAmount;
    b.validOrderAmount = m.validOrderAmount;
    b.disabledOrderAmount = m.orderAmount - m.validOrderAmount;
    b.validOrderPercent = safeToFloat(b.validOrderAmount / b.orderAmount * 100);
    b.disabledOrderPercent = safeToFloat(100 - b.validOrderPercent);
    b.validOrderPercentCeil = Math.ceil(b.validOrderPercent);
    console.log(b);
    const dotItems = [];
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 25; j++) {
        const num = j * 4 + 1 + i;
        // 0是无效，1是有效
        dotItems.push(num > b.validOrderPercentCeil ? 0 : 1);
      }
    }
    this.dotItems = dotItems;
    this.drawCharts();
  },
  methods: {
    drawCharts () {
      setTimeout(() => {
        const op1 = this.getLineOption();
        const temp = echarts.init(document.getElementById('orderLineDiv'));
        temp.setOption(op1);

        const op3 = this.getPieOption1();
        const temp3 = echarts.init(document.getElementById('orderPieDiv1'));
        temp3.setOption(op3);
      }, 100);
    },
    getLineOption () {
      const s = [];
      const rData = [];
      const pList = this.model.payOrderTrendModel.dataList;
      const vList = this.model.validOrderTrendModel.dataList;
      for (let i = 0; i < vList.length; i++) {
        rData.push(pList[i] - vList[i]);
      }
      for (let i = 0; i < 2; i++) {
        s.push({
          type: 'bar',
          name: i ? '有效订单' : '无效订单',
          barWidth: this.model.rangeType === 1 ? 10 : 2,
          label: {
            show: true,
            position: 'top',
            fontSize: 10
          },
          emphasis: { focus: 'series' },
          itemStyle: {
            borderRadius: 3,
            color: i ? DATA_COLOR.primary : DATA_COLOR.support
          },
          data: i ? vList : rData
        });
      }
      return {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.model.rangeOption,
          axisLabel: {
            fontSize: 10 // 设置字体大小为16
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          bottom: '0'
        },
        yAxis: {
          gridIndex: 0,
          max: function (value) { // 取最大值向上取整为最大刻度
            return Math.ceil(value.max * 1.5);
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '15%',
          top: '5%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        series: s
      };
    },
    getPieOption1 () {
      const list = JSON.parse(JSON.stringify(this.model.cityRatingList.dataList)).sort(sortBy('validOrderAmount', false));
      const legendList = [];
      const dataList = [];
      // 只展示前4个，其他归到其他
      list.forEach(v => {
        if (legendList.length < 4) {
          legendList.push(v.cityName);
          dataList.push({
            value: v.validOrderAmount, name: v.cityName
          });
        } else {
          if (legendList.length === 4) {
            legendList.push('其他');
            dataList.push({
              value: 0, name: '其他'
            });
          }
          dataList[4].value += v.validOrderAmount;
        }
      });
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}单 ({d}%)'
        },
        legend: {
          bottom: '0',
          data: legendList
        },
        color: [DATA_COLOR.primary, DATA_COLOR.adorn, DATA_COLOR.light, DATA_COLOR.support, 'rgba(240,240,240,1)'],
        series: [
          {
            name: '有效订单',
            type: 'pie',
            radius: ['5%', '60%'],
            center: ['50%', '52%'],
            itemStyle: {
              shadowBlur: 4,
              shadowOffsetX: 0,
              shadowColor: DATA_COLOR.primaryAlpha,
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 1
            },
            data: dataList,
            label: {
              alignTo: 'edge',
              formatter: '{b|{b}}:{d|{d}}{unit|%}',
              backgroundColor: DATA_COLOR.primaryAlphaDeep,
              edgeDistance: 10,
              // borderColor: DATA_COLOR.primary,
              rich: {
                hr: {
                  width: '100%',
                  height: 1,
                  backgroundColor: DATA_COLOR.primary
                },
                b: {
                  color: '#2D3B74',
                  align: 'center',
                  fontSize: 10,
                  padding: [1, 1, 0, 5],
                  lineHeight: 28
                },
                unit: {
                  padding: [0, 5, 0, 0]
                },
                d: {
                  color: '#2D3B74',
                  align: 'center',
                  fontSize: 10,
                  padding: [1, 0, 1, 1],
                  lineHeight: 28
                }
              }
            }
          }
        ]
      };
    }
  }
};
</script>
