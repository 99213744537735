<!--
    Created by 程雨喵'mac on 2024/5/11.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：金额分析
-->
<style lang="less">
.analysis-money-data {
  margin-top: @containerGap;
  padding: @containerGap;
  background-color: @backColorStrong;
  border-radius: @borderRadiusMin;
  box-shadow: @shadowMin;
  .money-table {
    margin-top: @containerGap;
    color: @textColorLight;
    font-size: 11px;
    border: 1px solid @lineColorNormal;
    border-radius: @borderRadiusMin;
    overflow: hidden;
    .money-tb-row {
      display: flex;
      line-height: 25px;
      .money-tb-col {
        .display-flex();
        height: 25px;
        overflow: hidden;
        text-align: center;
        .flex-grow(16.666666%);
      }
      &.money-tb-header {
        line-height: 13px;
        font-weight: 600;
        background-color: @backColorNormal;
        border-bottom: 0;
        font-size: 11px;
        .money-tb-col {
          .display-flex();
          height: 30px;
          white-space: pre-line;
        }
      }
    }
  }
}
</style>

<template>
  <div class="analysis-money-data">
    <div class="t1">
      金额分析
    </div>
    <div class="money-table">
      <div class="money-tb-row money-tb-header">
        <div
          v-for="(item, i) in columns"
          :key="i"
          class="money-tb-col"
          :class="'money-tb-col-' + item.col"
        >
          <span>{{ item.title }}</span>
        </div>
      </div>
      <div class="money-tb-body">
        <div
          v-for="(row, r) in dataList"
          :key="'row-' + r"
          class="money-tb-row"
        >
          <div
            v-for="(item, i) in columns"
            :key="i"
            class="money-tb-col"
            :class="'money-tb-col-' + item.col"
          >
            {{ row[item.key] }}
            <span v-if="item.isMoney">元</span>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-primary-top">
      <div
        id="moneyLineDiv"
        style="width:100%;height:220px"
      />
    </div>
  </div>
</template>

<script>
import { safeToFloat } from '@/libs/safe';
import { DATA_COLOR } from '../utils/colors';
const echarts = require('echarts');
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      columns: [
        { title: '科目', key: 'name' },
        { title: '总金额', key: 'money', isMoney: true },
        { title: '占比', key: 'percent' },
        { title: '客单价\n(有效订单)', key: 'validOrder', isMoney: true },
        { title: '客单价\n(支付订单)', key: 'payOrder', isMoney: true },
        { title: '人均', key: 'person', isMoney: true }
      ],
      // 支付金额，退款金额，实付金额
      dataList: [
        { name: '支付金额', money: 0, percent: '-', validOrder: 0, payOrder: 0, person: 0 },
        { name: '退款金额', money: 0, percent: '', validOrder: 0, payOrder: 0, person: 0 },
        { name: '实付金额', money: 0, percent: '', validOrder: 0, payOrder: 0, person: 0 }
      ]
    };
  },
  created () {
    this.initData();
    this.drawCharts();
  },
  methods: {
    initData () {
      const { totalAmountModel } = this.model;
      const list = this.dataList;
      // 支付金额
      list[0].money = totalAmountModel.payAmount;
      list[0].payOrder = safeToFloat(totalAmountModel.payAmount / totalAmountModel.orderAmount);
      list[0].validOrder = safeToFloat(totalAmountModel.payAmount / totalAmountModel.validOrderAmount);
      list[0].person = safeToFloat(totalAmountModel.payAmount / totalAmountModel.customerAmount);
      // 退款金额
      list[1].money = totalAmountModel.refundAmount;
      list[1].payOrder = safeToFloat(totalAmountModel.refundAmount / totalAmountModel.orderAmount);
      list[1].validOrder = safeToFloat(totalAmountModel.refundAmount / totalAmountModel.validOrderAmount);
      list[1].percent = safeToFloat(totalAmountModel.refundAmount / totalAmountModel.payAmount * 100) + '%';
      list[1].person = safeToFloat(totalAmountModel.refundAmount / totalAmountModel.customerAmount);
      // 实付金额
      list[2].money = totalAmountModel.realAmount;
      list[2].payOrder = safeToFloat(totalAmountModel.realAmount / totalAmountModel.orderAmount);
      list[2].validOrder = safeToFloat(totalAmountModel.realAmount / totalAmountModel.validOrderAmount);
      list[2].percent = safeToFloat(totalAmountModel.realAmount / totalAmountModel.payAmount * 100) + '%';
      list[2].person = safeToFloat(totalAmountModel.realAmount / totalAmountModel.customerAmount);
    },
    drawCharts () {
      setTimeout(() => {
        const op1 = this.getLineOption();
        const temp = echarts.init(document.getElementById('moneyLineDiv'));
        temp.setOption(op1);
      }, 100);
    },
    getLineOption () {
      const s = [];
      const rData = this.model.refundAmountTrendModel.dataList;
      const vList = this.model.realAmountTrendModel.dataList;
      for (let i = 0; i < 2; i++) {
        s.push({
          type: 'bar',
          name: i ? '实付金额' : '退款金额',
          barWidth: this.model.rangeType === 1 ? 10 : 2,
          label: {
            show: true,
            position: 'top',
            fontSize: 10
          },
          emphasis: { focus: 'series' },
          itemStyle: {
            borderRadius: 3,
            color: i ? DATA_COLOR.primary : DATA_COLOR.support
          },
          data: i ? vList : rData
        });
      }
      return {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.model.rangeOption,
          axisLabel: {
            fontSize: 10 // 设置字体大小为16
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          bottom: '0'
        },
        yAxis: {
          gridIndex: 0,
          max: function (value) { // 取最大值向上取整为最大刻度
            return Math.ceil(value.max * 1.5);
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '15%',
          top: '8%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        series: s
      };
    }
  }
};
</script>
