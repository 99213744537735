<!--
    Created by 程雨喵'mac on 2024/5/9.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：顶部标题
-->
<style lang="less">
.board-analysis-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: @containerGap;
  border-radius: @borderRadiusMin;
  box-shadow: @shadowMin;
  background: @backColorStrong url("/board/laptop.png") no-repeat;
  background-position-x: 95%;
  background-position-y: bottom;
  background-size: auto 80%;
  height: 190px;
  .service-name {
    font-size: 20px;
    font-weight: 600;
  }
  .service-info {
    margin: @containerGap * 1.5 0;
    line-height: 18px;
    white-space: pre-line;
    color: @textColorLight;
    span {
      color: @themeColor;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
</style>

<template>
  <div class="board-analysis-header">
    <!--服务商名称-->
    <div class="service-name">
      {{ model.serviceName }}
    </div>
    <!--简介-->
    <div
      class="service-info"
      v-html="headerInfo"
    />
    <!--按钮-->
    <div class="display-flex">
      <yg-button
        type="primary"
        @click="_clickReset"
      >
        重新生成
      </yg-button>
      <yg-button
        style="margin-left: 15px"
        @click="_clickPic"
      >
        生成图片
      </yg-button>
    </div>
    <yg-modal
      v-if="rangeTypeDialog"
      title="请选择时间筛选范围"
      width="360"
      sure-text="开始生成"
      @on-sure="onSure"
      @on-close="rangeTypeDialog=false"
    >
      <div slot="content">
        <Form>
          <FormItem
            label="时间颗粒度:"
            :label-width="90"
          >
            <Select
              v-model="rangeType"
              style="width:100px"
              transfer
            >
              <Option
                v-for="item in rangeTypeOption"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </Option>
            </Select>
          </FormItem>
          <FormItem
            label="时间范围:"
            :label-width="90"
          >
            <DatePicker
              v-model="rangeDate"
              :type="rangeType === 1 ? 'year' : 'month'"
              transfer
              :clearable="false"
            />
          </FormItem>
        </Form>
      </div>
    </yg-modal>
  </div>
</template>

<script>
import { formatDate } from '@/libs/utils';

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      rangeType: -1,
      rangeDate: '',
      rangeTypeOption: [
        { value: 1, label: '按年' },
        { value: 2, label: '按月' }
      ],
      rangeTypeDialog: false
    };
  },
  computed: {
    headerInfo () {
      const m = this.model;
      return `本页数据为<span>${this.model.rangeDateStr}(${this.model.rangeDay}天)</span>数据。\n此数据生成于${m.createTime}。`;
    }
  },
  created () { },
  methods: {
    onSure () {
      let d = new Date(this.rangeDate);
      d = formatDate(d);
      d = d.split('-');
      if (this.rangeType === 1) {
        d = d[0];
      } else {
        d = d[0] + '-' + d[1];
      }
      this.$emit('on-reset', this.rangeType, d);
    },
    _clickReset () {
      this.rangeType = this.model.rangeType;
      this.rangeDate = this.model.rangeDate;
      this.rangeTypeDialog = true;
    },
    _clickPic () {
      this.$emit('on-pic');
    }
  }
};
</script>
