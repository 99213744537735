<!--
    Created by 程雨喵'mac on 2024/5/9.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    核心数据
-->
<style lang="less">
.analysis-core-data-box {
  margin: 0 -@containerGap * 0.5;
  display: flex;
  flex-wrap: wrap;
  height: 190px;
  .core-data-item {
    .flex-grow(50%);
    height: 50%;
    padding: 0 @containerGap * 0.5;
    &.has-p-top {
      padding-top: @containerGap * 0.5;
    }
    &.has-p-bottom {
      padding-bottom: @containerGap * 0.5;
    }
    .core-data-item-inner {
      padding: 0 @containerGap;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: @backColorStrong;
      border-radius: @borderRadiusMin;
      box-shadow: @shadowMin;
      height: 100%;
      font-size: 12px;
      line-height: 12px;
      .d-tit {
        font-size: 14px;
        color: @textColorNormal;
      }
      .d-value {
        margin: 8px 0;
        font-size: 20px;
        line-height: 20px;
        color: @textColorStrong;
        font-weight: 600;
        .ivu-icon {
          opacity: 0.6;
          color: @textColorLight;
          font-size: 18px;
        }
      }
      .last-mon {
        display: flex;
        align-items: center;
        color: @textColorLight;
        .is-minus {
          color: @finishColor;
          font-weight: 600;
        }
        .is-plus {
          color: @errorColor;
          font-weight: 600;
        }
      }
    }
  }
}
</style>

<template>
  <div class="analysis-core-data-box">
    <div
      v-for="(item, i) in items"
      :key="i"
      class="core-data-item"
      :class="i > 1 ? 'has-p-top' : 'has-p-bottom'"
    >
      <div class="core-data-item-inner">
        <div class="d-tit">
          {{ item.title }}
        </div>
        <div class="d-value">
          <Poptip
            v-if="item.arithmetic"
            :content="item.arithmetic"
            :word-wrap="false"
            trigger="hover"
            :transfer="true"
          >
            <div class="display-flex display-flex-v-center">
              {{ boxData[item.currentKey] }}
              <Icon type="md-help-circle" />
            </div>
          </Poptip>
          <span v-else>{{ boxData[item.currentKey] }}</span>
        </div>
        <div
          v-if="item.lastMonthKey"
          class="last-mon"
          style="display: none"
        >
          <span>较{{ model.rangeType === 1 ? '上月' : '昨日' }}</span>
          <p
            v-if="boxData[item.lastMonthKey] < 0"
            class="is-minus"
          >
            <Icon
              type="md-arrow-down"
            />
            <span>{{ boxData[item.lastMonthKey] }}</span>
          </p>
          <p
            v-else-if="boxData[item.lastMonthKey] > 0"
            class="is-plus"
          >
            <Icon
              type="md-arrow-up"
            />
            <span>{{ boxData[item.lastMonthKey] }}</span>
          </p>
          <p v-else>
            无变化
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { safeToFloat } from '@/libs/safe';

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      boxData: {
        portAmount: 0,
        portAmountLast: 0,
        realAmount: 0,
        realAmountLast: 0,
        portRate: 0,
        portRateLast: 0,
        customerAmount: 0,
        customerAmountLast: 0
      },
      items: [
        {
          icon: '',
          title: '总端口数量',
          currentKey: 'portAmount',
          lastMonthKey: 'portAmountLast'
        },
        {
          icon: '',
          title: '总实收金额',
          currentKey: 'realAmount',
          lastMonthKey: 'realAmountLast'
        },
        {
          icon: '',
          title: '总端口使用率',
          arithmetic: '',
          currentKey: 'portRate',
          lastMonthKey: ''
        },
        {
          icon: '',
          title: '付款客户数',
          currentKey: 'customerAmount',
          lastMonthKey: 'customerAmountLast'
        }
      ]
    };
  },
  created () {
    this.initData();
  },
  methods: {
    initData () {
      const currentM = (new Date()).getMonth();
      const m = this.model;
      const tModel = m.totalAmountModel;
      this.boxData.portAmount = tModel.portAmount + '个';
      this.boxData.portAmountLast = m.portTrendModel.dataList[currentM];
      this.boxData.realAmount = tModel.realAmount + '元';
      this.boxData.realAmountLast = m.realAmountTrendModel.dataList[currentM];
      this.boxData.portRate = tModel.portRate + '%';

      this.items[2].arithmetic = `有效订单(${tModel.validOrderAmount}) ÷ 端口数(${tModel.portAmount}) ÷ 天数(${tModel.rangeDay}) × 100 = ${this.boxData.portRate}`;
      this.boxData.customerAmount = tModel.customerAmount + '人';
      this.boxData.customerAmountLast = m.customerTrendModel.dataList[currentM];
    }
  }
};
</script>
