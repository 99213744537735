<!--
    Created by 程雨喵'mac on 2024/5/11.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：充电时长分析
-->
<style lang="less">
.analysis-charge-time {
  margin-top: @containerGap;
  height: @containerGap * 7 + 560px;
  padding: @containerGap;
  background-color: @backColorStrong;
  border-radius: @borderRadiusMin;
  box-shadow: @shadowMin;
  .echarts-box-gap {
    margin-top: @containerGap;
    padding-top: @containerGap;
    border-top: 1px solid @lineColorLight;
  }
}
</style>

<template>
  <div class="analysis-charge-time">
    <div class="t1">
      充电时长
    </div>
    <div class="margin-primary-top">
      <div
        id="chargeLineDiv"
        style="width:100%;height:220px"
      />
    </div>
    <div class="echarts-box-gap">
      <div class="t1 display-flex display-flex-h-between">
        充电时段分布
        <div class="top-tab">
          <div
            v-for="(item, i) in tab.items"
            :key="i"
            class="tab-item"
            :class="i === tab.index ? 'is-current' : ''"
            @click.stop="_clickTab(i)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div
        id="chargeLineDiv2"
        style="width:100%;height: 340px"
      />
    </div>
  </div>
</template>

<script>
import { DATA_COLOR } from '@/pages/300__board/dataAnalysis/utils/colors';
const echarts = require('echarts');

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      tab: {
        items: ['开始充电', '结束充电'],
        index: 0
      }
    };
  },
  mounted () {
    this.drawCharts();
  },
  methods: {
    _clickTab (i) {
      if (i !== this.tab.index) {
        this.tab.index = i;
        this.drawCharts();
      }
    },
    drawCharts () {
      setTimeout(() => {
        const op1 = this.getLineOption();
        const temp = echarts.init(document.getElementById('chargeLineDiv'));
        temp.setOption(op1);

        const op3 = this.getTimeLineOption();
        const temp3 = echarts.init(document.getElementById('chargeLineDiv2'));
        temp3.setOption(op3);
      }, 100);
    },
    getLineOption () {
      const s = [];
      // const pList = [];
      const vList = this.model.realChargeDurationTrendModel.dataList;
      for (let i = 0; i < 1; i++) {
        s.push({
          type: 'bar',
          name: !i ? '充电时长' : '使用电量',
          barWidth: this.model.rangeType === 1 ? 10 : 2,
          label: {
            show: true,
            position: 'top',
            fontSize: 10
          },
          emphasis: { focus: 'series' },
          itemStyle: {
            borderRadius: 3,
            color: !i ? DATA_COLOR.primary : DATA_COLOR.support
          },
          data: !i ? vList : []
        });
      }
      return {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.model.rangeOption,
          axisLabel: {
            fontSize: 10 // 设置字体大小为16
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          bottom: '0'
        },
        yAxis: {
          gridIndex: 0,
          name: '时长',
          axisLabel: {
            formatter: '{value} 分钟'
          },
          max: function (value) { // 取最大值向上取整为最大刻度
            return Math.ceil(value.max * 1.2);
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '15%',
          top: '5%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        series: s
      };
    },
    getTimeLineOption () {
      let max = 0;
      const list = this.model.realChargeTimeLineModel[this.tab.index === 0 ? 'startDataList' : 'endDataList'];
      list.forEach(v => {
        if (max < v[2]) {
          max = v[2];
        }
      });
      return {
        tooltip: {
          position: 'top',
          textStyle: {
            fontSize: 12
          },
          formatter: (params) => {
            // params 是一个数组，数组中包含每个系列的数据信息
            let result = '';
            [params].forEach(function (item) {
              // item 是每一个系列的数据
              const seriesName = item.seriesName; // 系列名称
              const value = item.value[2]; // 数据值
              result += `${seriesName}: ${value}单<br/>`;
            });
            return result;
          }
        },
        grid: {
          top: '5%',
          left: '10%',
          right: '3%',
          bottom: '20%'
        },
        xAxis: {
          type: 'category',
          data: this.model.rangeOption,
          splitArea: {
            show: true
          }
        },
        yAxis: {
          type: 'category',
          data: this.model.realChargeTimeLineModel.times,
          splitArea: {
            show: true
          }
        },
        visualMap: {
          min: 0,
          max: max,
          // max: function (value) { // 取最大值向上取整为最大刻度
          //   console.log(value);
          //   return 2000;
          // },
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '0%',
          inRange: {
            color: ['#dbf6f1', DATA_COLOR.adorn],
            opacity: 1
          }
        },
        series: [
          {
            name: `此时段${this.tab.index === 0 ? '开始' : '结束'}充电单数`,
            type: 'heatmap',
            data: list,
            label: {
              show: true,
              formatter: function (params) {
                return (params.value[2]);
              },
              fontSize: 10
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 2,
                shadowColor: 'rgba(0, 0, 0, 0.1)'
              }
            }
          }
        ]
      };
    }
  }
};
</script>
