<!--
    Created by 程雨喵'mac on 2024/5/10.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：数据排行
-->
<style lang="less">
.analysis-data-ranking {
  margin-top: @containerGap;
  padding: @containerGap;
  background-color: @backColorStrong;
  border-radius: @borderRadiusMin;
  box-shadow: @shadowMin;
  height: 340px;
  p.sub-tit {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-left: 5px;
    flex: 1;
    color: @textColorLight;
  }
  .ranking-table {
    margin-top: @containerGap;
    color: @textColorLight;
    font-size: 12px;
    .ranking-tb-row {
      display: flex;
      line-height: 25px;
      border-bottom: 1px solid @lineColorNormal;
      .ranking-tb-col {
        height: 25px;
        overflow: hidden;
        text-align: center;
        &.ranking-tb-col-s {
          .display-flex();
          .flex-grow(30px);
          span {
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 50%;
            background-color: @textColorLight;
            color: white;
            font-size: 10px;
            &.bg-color-0 { background-color: @themeColor; }
            &.bg-color-1 { background-color: @finishColor; }
            &.bg-color-2 { background-color: @adornColor; }
          }
        }
        &.ranking-tb-col-m {
          .flex-grow(10%);
        }
        &.ranking-tb-col-l {
          flex: 1;
        }
      }
      &.ranking-tb-header {
        line-height: 13px;
        font-weight: 600;
        background-color: @backColorNormal;
        border-bottom: 0;
        font-size: 11px;
        .ranking-tb-col {
          .display-flex();
          height: 40px;
          white-space: pre-line;
          .sort-box {
            margin-left: 5px;
            .display-flex();
            flex-direction: column;
            div {
              .display-flex();
              cursor: pointer;
              &.is-current-sort {
                img {
                  filter: invert(0);
                  //filter: hue-rotate(200deg);
                }
              }
              &+div {
                margin-top: 5px;
              }
              img {
                filter: invert(200%);
                width: auto;
                height: 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="analysis-data-ranking">
    <div class="t1 display-flex display-flex-v-end">
      <div>
        {{ model.rangeDateStr }}数据汇总排行
      </div>
      <p class="sub-tit">
        仅展示10名以内的排名
      </p>
      <div class="top-tab">
        <div
          v-for="(item, i) in tab.items"
          :key="i"
          class="tab-item"
          :class="i === tab.index ? 'is-current' : ''"
          @click.stop="_clickTab(i)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="ranking-table">
      <div class="ranking-tb-row ranking-tb-header">
        <div class="ranking-tb-col ranking-tb-col-s" />
        <div
          v-for="(item, i) in columns"
          :key="i"
          class="ranking-tb-col"
          :class="'ranking-tb-col-' + item.col"
        >
          <Poptip
            v-if="item.tip"
            :content="item.tip"
            :word-wrap="false"
            trigger="hover"
            :transfer="true"
          >
            <div class="display-flex display-flex-v-center">
              {{ item.title }}
            </div>
          </Poptip>
          <span v-else>{{ item.title }}</span>
          <div
            v-if="item.canSort"
            class="sort-box"
          >
            <div
              :class="currentSort.key === item.key && !currentSort.isDown ? 'is-current-sort' : ''"
              @click.stop="listSort(item.key, false)"
            >
              <img
                src="/board/up.png"
                alt=""
              >
            </div>
            <div
              :class="currentSort.key === item.key && currentSort.isDown ? 'is-current-sort' : ''"
              @click.stop="listSort(item.key, true)"
            >
              <img
                src="/board/down.png"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
      <div class="ranking-tb-body">
        <div
          v-for="(row, r) in dataList"
          :key="'row-' + r"
          class="ranking-tb-row"
        >
          <div class="ranking-tb-col ranking-tb-col-s">
            <span
              v-show="r < 9"
              :class="'bg-color-' + r"
            >{{ r + 1 }}</span>
          </div>
          <div
            v-for="(item, i) in columns"
            :key="i"
            class="ranking-tb-col"
            :class="'ranking-tb-col-' + item.col"
          >
            <Poptip
              :content="row[item.key]"
              :word-wrap="false"
              trigger="hover"
              :transfer="true"
            >
              <div class="display-flex display-flex-v-center">
                {{ row[item.key] }}
              </div>
            </Poptip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy } from '@/libs/utils';

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      tab: {
        items: ['城市维度', '小区维度'],
        index: 0
      },
      dataList: [],
      columns: [
        { canSort: false, col: 'l', title: '名称', key: 'cityName' },
        { canSort: true, col: 'm', title: '设备\n数量', key: 'deviceAmount', tip: '已启用的的设备总数' },
        { canSort: true, col: 'm', title: '端口\n数量', key: 'portAmount', tip: '已启用的设备下的端口总数' },
        { canSort: true, col: 'm', title: '支付\n订单', key: 'orderAmount', tip: '支付成功的订单总数' },
        { canSort: true, col: 'm', title: '有效\n订单', key: 'validOrderAmount', tip: '有实际支付金额的订单总数' },
        { canSort: true, col: 'm', title: '支付\n金额', key: 'payAmount', tip: '支付订单的支付金额汇总' },
        { canSort: true, col: 'm', title: '实收\n金额', key: 'realAmount', tip: '支付订单的实际收款金额汇总' },
        { canSort: true, col: 'm', title: '退款\n金额', key: 'refundAmount', tip: '支付金额 - 退款金额' },
        { canSort: true, col: 'm', title: '支付\n客单价', key: 'payOrderPrice', tip: '支付金额 ÷ 支付订单数' },
        { canSort: true, col: 'm', title: '端口\n使用率', key: 'portRate', tip: '有效订单 ÷ 端口数 ÷ 天数 × 100' }
      ],
      currentSort: {
        key: '',
        isDown: false
      }
    };
  },
  created () {
    this.listSort();
  },
  methods: {
    _clickTab (i) {
      if (i !== this.tab.index) {
        this.tab.index = i;
        this.listSort();
      }
    },
    listSort (key = 'portRate', down = false) {
      const list = JSON.parse(JSON.stringify(this.model[this.tab.index === 0 ? 'cityRatingList' : 'communityRatingList'].dataList));
      this.currentSort.key = key;
      this.currentSort.isDown = down;
      this.dataList = list.sort(sortBy(key, down)).slice(0, 9);
    }
  }
};
</script>
