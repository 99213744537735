<!--
    Created by 程雨喵'mac on 2024/5/6.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
-->
<style lang="less">
.data-analysis-page {
  background-color: @backColorNormal;
  padding: @containerGap;
  height: 100vh;
  overflow: auto;
  .page-main-box {
    margin: 0 auto;
    transition: all @transitionTime15;
    min-width: 800px;
    &.page-main-box-loading {
      padding: @containerGap;
      width: 1000px;
      background-color: @backColorStrong;
      height: 100%;
      overflow: auto;
      box-shadow: @shadowMid;
      border-radius: @borderRadiusMid;
    }
    .org-data-loading {
      .data-loading-center-tit {
        padding: @containerGap * 2;
        .display-flex();
        flex-direction: column;
        div {
          font-size: 20px;
          font-weight: 600;
          color: @textColorNormal;
        }
        p {
          position: relative;
          margin-top: 8px;
          padding-top: 5px;
          color: @textColorLight;
          font-weight: 400;
          &::before {
            content: '';
            position: absolute;
            left: -10px;
            right: -10px;
            top: 0;
            height: 1px;
            background-color: @lineColorNormal;
          }
        }
      }
      .loading-step-row {
        margin-bottom: @containerGap * 2;
        &.is-current {
          .loading-step-header {
            .dot {
              background-color: @themeColor;
              color: @textColorFlip;
            }
          }
        }
        .loading-step-header {
          display: flex;
          align-items: center;
          cursor: default;
          color: @textColorNormal;
          .dot {
            margin-right: @containerGap;
            .display-flex();
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: 1px solid @themeColor;
            font-size: 13px;
            color: @textColorPrimary;
          }
          .tit {
            font-size: 17px;
            font-weight: 600;
          }
          .sub {
            margin-left: @containerGap;
            font-size: 12px;
            color: @textColorLight;
            span {
              color: @textColorPrimary;
              font-weight: 600;
            }
          }
        }
        .loading-step-table {
          margin-top: @containerGap;
          margin-left: @containerGap * 2;
          font-size: 12px;
          border-top: 1px solid @lineColorNormal;
          border-left: 1px solid @lineColorNormal;
          border-bottom: 1px solid @lineColorNormal;
          transition: @transitionTime15 all;
          .loading-tb-body {
            position: relative;
            @gapLoadingDuration: 1.5s;
            border-top: 1px solid @lineColorNormal;
            max-height: 360px;
            overflow: hidden;
            .loading-tb-body-inner {
              position: relative;
              @keyframes tbAnim {
                0% { transform: translateY(-100%)}
                100% { transform: translateY(0)}
              }
              @keyframes lineAnim {
                0% { bottom: 0}
                //50% { bottom: 90%}
                100% { bottom: 100%}
              }
              &.start-anim {
                animation-delay: 0.2s;
                animation: @gapLoadingDuration tbAnim forwards;
              }
            }
            .anim-mask {
              position: absolute;
              z-index: 1;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: rgba(255, 255, 255, 0.5);
              &.loading-amin {
                .display-flex();
                background-color: rgba(255, 255, 255, 0.7);
                backdrop-filter: blur(2px);
              }
              &.scan-amin {
                .line {
                  position: absolute;
                  left: 0;
                  right: 0;
                  height: 100px;
                  background: transparent url("/board/gird1.png") no-repeat;
                  background-size: 100% 100%;
                  opacity: 0.8;
                  animation: @gapLoadingDuration lineAnim;
                }
              }
            }
            .loading-tb-body-status {
              font-size: 14px;
              padding: @containerGap * 2 @containerGap;
              border-right: 1px solid @lineColorNormal;
              .sts-item {
                .display-flex();
                .ivu-icon-md-checkmark-circle {
                  margin-right: 8px;
                  font-size: 18px;
                  color: @finishColor;
                }
                .ivu-icon-md-remove-circle {
                  margin-right: 8px;
                  font-size: 18px;
                  color: @textColorLight;
                }
              }
            }
          }
          .loading-tb-step-row {
            display: flex;
            line-height: 18px;
            text-align: center;
            &+.loading-tb-step-row {
              border-top: 1px solid @lineColorNormal;
            }
            &.loading-tb-step-header {
              background-color: @backColorNormal;
              line-height: 22px;
              height: 22px;
              font-weight: 600;
            }
            .step-tb-col {
              .flex-grow(20%);
              border-right: 1px solid @lineColorNormal;
              overflow: hidden;
              height: 18px;
            }
          }
        }
        .circle-box {
          .display-flex();
          padding: @containerGap * 2;
          .circle-inner-custom {
            .display-flex();
            flex-direction: column;
            .p {
              p {
                position: relative;
                color: @textColorLight;
                font-size: 16px;
                &::after {
                  content: '';
                  position: absolute;
                  left: 10px;
                  right: 10px;
                  height: 1px;
                  bottom: -8px;
                  background-color: #ebebeb;
                }
              }
              div {
                margin-top: 22px;
                color: @textColorPrimary;
                font-size: 20px;
              }
            }
            .finish-box {
              .display-flex();
              flex-direction: column;
              p {
                margin-bottom: @containerGap;
                font-size: 20px;
              }
              .btn {
                text-decoration: underline;
                color: @finishColor;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .data-board {
      display: flex;
      flex-wrap: wrap;
      //height: 100%;
      //overflow: auto;
      background-color: @backColorNormal;
      //width: 3820px;
      .data-board-col-outer-big {
        box-sizing: border-box;
        @media screen and (min-width: 1600px) {.flex-grow(40%)}
        @media screen and (max-width: 1600px) {.flex-grow(50%)}
        @media screen and (max-width: 1200px) {.flex-grow(100%);}
      }
      .data-board-col-outer-flex {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        @media screen and (min-width: 1600px) {padding-left: 0;}
        @media screen and (max-width: 1600px) {padding-left: @containerGap;}
        @media screen and (max-width: 1200px) {padding-left: 0; padding-top: 0}
        .data-board-flex-sub {
          @media screen and (min-width: 1600px) {.flex-grow(50%);padding-left: @containerGap;}
          @media screen and (max-width: 1600px) {.flex-grow(100%);padding-left: 0;}
          @media screen and (max-width: 1200px) {.flex-grow(100%);padding-left: 0;padding-top: @containerGap}
          box-sizing: border-box;
        }
      }
      .t1 {
        font-size: 15px;
        font-weight: 600;
      }
      .top-tab {
        display: flex;
        font-weight: 400;
        font-size: 12px;
        .tab-item {
          padding: 0 10px;
          margin-left: 10px;
          border-radius: @borderRadiusMin;
          border: 1px solid @themeColor;
          background-color: @backColorNormal;
          color: @textColorLight;
          cursor: pointer;
          &.is-current {
            background-color: @themeColor;
            color: white;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    v-if="isServiceProvider"
    class="data-analysis-page"
  >
    <div
      class="page-main-box"
      :class="isLoading ? 'page-main-box-loading' : ''"
    >
      <!--请求数据-->
      <div
        v-if="isLoading"
        class="org-data-loading"
      >
        <div class="data-loading-center-tit">
          <div>{{ dataModel.serviceName }}</div>
          <p>数据看板生成中，请耐心等待</p>
        </div>
        <!--步骤-->
        <div
          v-for="(item, i) in loadingStepItems"
          :key="i"
          class="loading-step-row"
          :class="i === loadingStep ? 'is-current' : ''"
        >
          <!--步骤顶部标题-->
          <a
            :id="'a-step-' + i"
            class="loading-step-header"
          >
            <div class="dot">
              {{ i + 1 }}
            </div>
            <div class="tit">
              {{ item.title }}
            </div>
            <div
              v-if="item.total"
              class="sub"
            >
              解析进度：<span>{{ item.current }}</span> /{{ item.total }}条
            </div>
          </a>
          <!--步骤表格-->
          <div class="loading-step-table">
            <!--表格头部-->
            <div class="loading-tb-step-row loading-tb-step-header">
              <div
                v-for="(col, c) in item.columns"
                :key="'c-' + c"
                class="step-tb-col"
              >
                {{ col.title }}
              </div>
            </div>
            <!--表格body-->
            <div class="loading-tb-body">
              <!--遮罩-->
              <div
                v-if="i === loadingStep"
                class="anim-mask"
                :class="item.isLoading ? 'loading-amin' : 'scan-amin'"
              >
                <Spin
                  v-if="item.isLoading"
                  size="large"
                />
                <div
                  v-else
                  class="line"
                />
              </div>
              <!--实际表格区域-->
              <div
                v-if="i === loadingStep"
                ref="loadingTbBody"
                class="loading-tb-body-inner"
                :class="item.isLoading ? '' : 'start-anim'"
                :style="dataList[item.dataListKey].length === 0 ? 'height: 200px' : ''"
              >
                <div
                  v-for="(row, r) in dataList[item.dataListKey].slice(dataList[item.dataListKey].length - 200, dataList[item.dataListKey].length)"
                  :key="'r-' + r"
                  class="loading-tb-step-row"
                >
                  <div
                    v-for="(col, c) in item.columns"
                    :key="'c1-' + c"
                    class="step-tb-col"
                  >
                    {{ row[col.key] || '-' }}
                  </div>
                </div>
              </div>
              <div
                v-else
                class="loading-tb-body-status"
              >
                <div
                  v-if="loadingStep > i"
                  class="sts-item"
                >
                  <Icon type="md-checkmark-circle" />
                  <span>已解析完成</span>
                </div>
                <div
                  v-if="loadingStep < i"
                  class="sts-item"
                >
                  <Icon type="md-remove-circle" />
                  <span>等待上一步骤解析</span>
                </div>
                <!--<span v-if="loadingStep > i">已解析完成</span>-->
              </div>
            </div>
          </div>
        </div>
        <div
          class="loading-step-row"
          :class="2 === loadingStep ? 'is-current' : ''"
        >
          <a
            id="a-step-2"
            class="loading-step-header"
          >
            <div class="dot">
              3
            </div>
            <div class="tit">
              等待看板生成
            </div>
          </a>
          <div class="circle-box">
            <i-circle
              :percent="circlePercent"
              :stroke-color="circleColor"
              :size="200"
            >
              <div
                class="circle-inner-custom"
              >
                <div
                  v-if="circlePercent !== 100"
                  class="p"
                >
                  <p>{{ circleText }}</p>
                  <div>
                    {{ circlePercent }}%
                  </div>
                </div>
                <div
                  v-else
                  class="finish-box"
                >
                  <p>数据已就绪!</p>
                  <div
                    class="btn"
                    @click="_clickDraw"
                  >
                    立即查看
                  </div>
                </div>
              </div>
            </i-circle>
          </div>
        </div>
      </div>
      <!--数据看板-->
      <div
        v-else-if="!isLoading && !isResize"
        id="dataBoard"
        class="data-board"
      >
        <div class="data-board-col-outer-big">
          <v-analysis-header
            :model="dataModel"
            @on-reset="initData"
            @on-pic="_clickDownload"
          />
          <v-data-raking :model="dataModel" />
          <v-charge-time :model="dataModel" />
        </div>
        <div class="data-board-col-outer-flex">
          <div class="data-board-flex-sub">
            <v-core-data-box :model="dataModel" />
            <v-data-path :model="dataModel" />
            <v-order-data :model="dataModel" />
          </div>
          <div class="data-board-flex-sub">
            <v-rate-data :model="dataModel" />
            <v-grow-up :model="dataModel" />
            <v-money-data :model="dataModel" />
          </div>
        </div>
      </div>
      <div
        v-else
        class="data-board"
      >
        页面重新布局中
      </div>
    </div>
  </div>
  <div v-else>
    <v-401 />
  </div>
</template>

<script>
import mixinDivCreateImage from '@/libs/mixins/divCreateImage';
import { onHandler, offHandler, getMonthDayAmount } from '@/libs/utils';
import v401 from '../../400__error/401.vue';
import OrderApiSet from '@/request/api/OrderApiSet';
import DeviceApiSet from '@/request/api/DeviceApiSet';
import CustomerApiSet from '@/request/api/CustomerApiSet';
import DataAnalysisModel from './model/DataAnalysisModel';
import vAnalysisHeader from './view/analysis-header.vue';
import vCoreDataBox from './view/core-data.vue';
import vDataPath from './view/data-path.vue';
import vGrowUp from './view/grow-up.vue';
import vDataRaking from './view/data-ranking.vue';
import vOrderData from './view/order-data.vue';
import vMoneyData from './view/money-data.vue';
import vChargeTime from './view/charge-time.vue';
import vRateData from './view/rate-data.vue';

export default {
  components: { v401, vAnalysisHeader, vCoreDataBox, vDataPath, vGrowUp, vDataRaking, vOrderData, vMoneyData, vChargeTime, vRateData },
  mixins: [mixinDivCreateImage],
  data () {
    return {
      isLoading: false,
      loadingStep: 0,
      pageSize: 500,
      loadingStepItems: [
        {
          title: '解析订单数据',
          dataListKey: 'order',
          total: 100,
          current: 0,
          isLoading: false,
          columns: [
            { title: '订单编码', key: 'orderNo' },
            { title: '开始时间', key: 'startTimeStr' },
            { title: '所属省市地', key: 'addressName' },
            { title: '设备编码', key: 'deviceNumber' },
            { title: '支付金额', key: 'payMoney' }
            // { title: '实收金额', key: 'realMoney' }
          ]
        },
        {
          title: '解析设备数据',
          dataListKey: 'device',
          total: 0,
          current: 0,
          isLoading: false,
          columns: [
            { title: '设备编号', key: 'deviceNumber' },
            { title: '创建时间', key: 'createTime' },
            { title: '端口数量', key: 'devicePortCount' },
            { title: '城市名称', key: 'cityName' },
            { title: '小区名称', key: 'communityName' }
          ]
        }
        // {
        //   title: '解析客户数据',
        //   dataListKey: 'customer',
        //   total: 0,
        //   current: 0,
        //   isLoading: false,
        //   columns: [
        //     { title: '客户手机号', key: 'mobile' },
        //     { title: '客户编号', key: 'customerCode' },
        //     { title: '下单次数', key: 'devicePortCount' },
        //     { title: '总支付金额', key: 'cityName' },
        //     { title: '总退款金额', key: 'communityName' }
        //   ]
        // }
      ],
      circleTimer: null,
      circlePercent: 0,
      // 最后请求数据的时间
      lastRequestTime: '',
      dataList: {
        order: [],
        device: [],
        customer: []
      },
      dataModel: null,
      isResize: false,
      dataRequestTimer: null
    };
  },
  computed: {
    isServiceProvider () {
      return this.$storage.user.userType === this.$config.systemUserType.service || this.$storage.user.roles.find(v => v.id === 90028);
      // return this.$storage.user.userType === this.$config.systemUserType.service && $analysisIndexDB.dbOpenSuccess;
    },
    circleText () {
      if (this.circlePercent < 50) {
        return '订单数据生成';
      } else if (this.circlePercent < 90) {
        return '设备数据生成';
      }
      return '客户数据生成';
    },
    circleColor () {
      if (this.circlePercent < 100) {
        return '#546EFD';
      }
      return '#1BC5BD';
    }
  },
  created () {
    const userInfo = this.$storage.user;
    if (userInfo && userInfo.userId && (userInfo.userType === 20 || this.$storage.user.roles.find(v => v.id === 90028))) {
      // if (!this.$storage.user)
      const model = this.$storage.analysisData;
      // console.log(model.userId);
      if (model) {
        if (model.userId === userInfo.userId) {
          this.dataModel = model;
        } else {
          this.initData();
        }
      } else {
        this.initData();
      }
      this.startDataRequestTimer();
      onHandler(window, 'resize', this.resizeWindow);
    } else {
      this.$Modal.info({
        title: '温馨提示',
        content: '无权限查看'
      });
    }
  },
  destroyed () {
    this.clearCircleTimer();
    this.clearDataRequestTimer();
    offHandler(window, 'resize', this.resizeWindow);
  },
  methods: {
    resizeWindow () {
      this.isResize = true;
      setTimeout(() => {
        this.isResize = false;
      }, 500);
    },
    startDataRequestTimer () {
      this.clearDataRequestTimer();
      // 59分钟看一次
      this.dataRequestTimer = setInterval(() => {
        const dd = new Date();
        if (dd.getHours() === 15) {
          this.initData();
        }
      }, 1000 * 60 * 59);
    },
    clearDataRequestTimer () {
      if (this.dataRequestTimer) {
        clearInterval(this.dataRequestTimer);
        this.dataRequestTimer = null;
      }
    },
    initData (rangeType = 1, rangeDate = '2024') {
      const merchantInfo = this.$storage.merchantInfo;
      const userInfo = this.$storage.user;
      this.dataModel = new DataAnalysisModel(rangeType, rangeDate);
      this.dataModel.serviceName = merchantInfo.propertyCompanyName || merchantInfo.providerName;
      this.dataModel.userId = userInfo.userId;
      this.loadingStep = 0;
      this.circlePercent = 0;
      this.isLoading = true;
      this.dataList = {
        order: [],
        device: [],
        customer: []
      };
      this.loadingStepItems.forEach(v => {
        v.total = 0;
        v.current = 0;
      });
      this.requestData(1);
    },
    startCircleTimer () {
      this.circlePercent = 0;
      this.clearCircleTimer();
      this.circleTimer = setInterval(() => {
        if (this.circlePercent === 0) {
          this.dataModel.startAnalysis({
            deviceList: this.dataList.device,
            orderList: this.dataList.order,
            customerList: this.dataList.customer
          });
          this.$storage.analysisData = this.dataModel;
        }
        const num = this.circlePercent + Math.random() * 20;
        if (num >= 100) {
          this.circlePercent = 100;
          this.clearCircleTimer();
        } else {
          this.circlePercent = Math.floor(num);
        }
      }, 100);
    },
    clearCircleTimer () {
      if (this.circleTimer) {
        clearInterval(this.circleTimer);
        this.circleTimer = null;
      }
    },
    // 渲染看板
    _clickDraw () {
      this.isLoading = false;
      this.dataList = null;
    },
    _clickDownload () {
      this.$ygLoading.show();
      this.mixinImageCreatePic('dataBoard', '数据看板');
      setTimeout(() => {
        this.$ygLoading.close();
      }, 1000);
    },
    requestData (page) {
      const step = this.loadingStep;
      let api = null;
      if (step === 0) {
        let startTimeEnd = '';
        let startTimeStart = '';
        const dM = this.dataModel;
        if (dM.rangeType === 1) {
          startTimeStart = dM.rangeDate + '-01-01 00:00:00';
          startTimeEnd = dM.rangeDate + '-12-31 23:59:59';
        } else {
          startTimeStart = dM.rangeDate + '-01 00:00:00';
          startTimeEnd = dM.rangeDate + '-' + getMonthDayAmount(dM.rangeDate + '-01') + ' 23:59:59';
        }
        api = OrderApiSet.getOrderList;
        api.params = {
          chargeStatus: '2',
          pageNo: page,
          pageSize: this.pageSize,
          startTimeEnd: startTimeEnd,
          startTimeStart: startTimeStart
        };
      } else if (step === 1) {
        api = DeviceApiSet.deviceList;
        api.params = {
          status: '1',
          // deviceSource: '0',
          pageNo: page,
          pageSize: this.pageSize
        };
      } else {
        api = CustomerApiSet.getUserListApi;
        api.params = {
          pageNo: page,
          pageSize: this.pageSize
        };
      }
      api.autoConfig.autoLoading = false;
      api.autoConfig.autoSaveMonitor = false;
      this.loadingStepItems[step].isLoading = true;
      this.$http(api).then(res => {
        const resData = res.data;
        const total = resData.total;
        this.loadingStepItems[step].isLoading = false;
        this.loadingStepItems[step].total = total;
        let c = page * this.pageSize;
        if (c > total) c = total;
        this.loadingStepItems[step].current = c;
        const dataKeyArr = ['order', 'device'];
        // const dataKeyArr = ['order', 'device', 'customer'];
        this.dataList[dataKeyArr[step]] = [...this.dataList[dataKeyArr[step]], ...resData.rows];
        if (this.dataList[dataKeyArr[step]].length < total) {
          // if (this.dataList.order.length === 0) {
          setTimeout(() => {
            this.requestData(page + 1);
          }, 1500);
        } else {
          setTimeout(() => {
            this.loadingStep = step + 1;
            document.querySelector('#a-step-' + this.loadingStep).scrollIntoView();
            if (this.loadingStep === 2) {
              this.startCircleTimer();
              return;
            }
            this.requestData(1);
          }, 1500);
        }
      });
    }
  }
};
</script>
