<!--
    Created by 程雨喵'mac on 2024/5/9.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：数据流向(轨迹)
-->
<style lang="less">
.data-path-box {
  margin-top: @containerGap;
  padding: @containerGap;
  background-color: @backColorStrong;
  border-radius: @borderRadiusMin;
  box-shadow: @shadowMin;
  height: 340px;
  .path-row {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    .path-item {
      .display-flex();
      flex-direction: column;
      .flex-grow(120px);
      height: 60px;
      background-color: @backColorNormal;
      border-radius: @borderRadiusMin;
      .value {
        .display-flex();
        align-items: flex-end;
        p {
          font-size: 18px;
          line-height: 16px;
          font-weight: 600;
        }
        span {
          color: @textColorLight;
        }
      }
      .label {
        margin-top: 5px;
        color: @textColorLight;
        font-size: 13px;
        line-height: 13px;
      }
    }
    .v-line {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 @containerGap * 0.5 0 @containerGap;
      flex: 1;
      height: 2px;
      color: @textColorLight;
      &.is-reserve {
        margin: 0 @containerGap 0 @containerGap * 0.5;
        .line-inner {
          margin-right: 0;
          margin-left: -8px;
          span {
            left: 90%;
            animation: 2.4s dotAnim infinite linear reverse;
          }
        }
      }
      .line-text {
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
        text-align: center;
        span {
          color: @themeColor;
          font-weight: 600;
        }
      }
      .line-inner {
        position: relative;
        margin-right: -8px;
        flex: 1;
        height: 100%;
        background-color: @textColorLight;
        @keyframes dotAnim {
          0% { left: 10%; }
          100% { left: 90%; }
        }
        span {
          position: absolute;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          top: -3px;
          opacity: 0.5;
          left: 10%;
          background-color: @themeColor;
          animation: 2.4s dotAnim infinite linear;
          &.dot {
            &-1 { animation-delay: 0.8s };
            &-2 { animation-delay: 1.6s };
            &-3 { animation-delay: 2.4s };
            &-4 { animation: none };
          }
        }
      }
      .ivu-icon {
        font-size: 22px;
      }
    }
  }
  .h-line-outer {
    height: 50px;
    display: flex;
    &.is-right {
      justify-content: flex-end;
      .h-line {
        .line-text {
          left: auto;
          right: 10px;
          text-align: right;
        }
      }
    }
    .h-line {
      margin: 0 59px;
      padding-top: @containerGap * 0.5;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 2px;
      color: @textColorLight;
      height: 100%;
      .ivu-icon {
        font-size: 22px;
      }
      .line-text {
        position: absolute;
        top: 50%;
        left: 10px;
        white-space: nowrap;
        transform: translateY(-50%);
        span {
          color: @themeColor;
          font-weight: 600;
        }
      }
      .line-inner {
        position: relative;
        flex: 1;
        width: 100%;
        background-color: @textColorLight;
        margin-bottom: -8px;
        @keyframes dotAnim1 {
          0% { top: 0; }
          100% { top: 30px; }
        }
      }
    }
  }
}
</style>

<template>
  <div class="data-path-box">
    <div class="t1">
      数据流向
    </div>
    <!--小区+端口-->
    <div class="path-row margin-primary-top">
      <div class="path-item">
        <div class="value">
          <p>{{ boxData.communityAmount }}</p>
          <span>个</span>
        </div>
        <div class="label">
          小区总数
        </div>
      </div>
      <div class="v-line">
        <div class="line-text">
          每小区平均<span>{{ pathData.p1 }}个</span>端口
        </div>
        <div class="line-inner">
          <span class="dot-1" />
          <span class="dot-2" />
          <span class="dot-3" />
          <span class="dot-4" />
        </div>
        <Icon type="md-arrow-dropright" />
      </div>
      <div class="path-item">
        <div class="value">
          <p>{{ boxData.portAmount }}</p>
          <span>个</span>
        </div>
        <div class="label">
          端口总数
        </div>
      </div>
    </div>
    <div
      class="h-line-outer is-right"
    >
      <!--向下竖线-->
      <div class="h-line">
        <div class="line-text">
          每端口平均<span>{{ pathData.p2 }}个</span>订单
        </div>
        <div class="line-inner" />
        <Icon type="md-arrow-dropdown" />
      </div>
    </div>
    <!--支付订单+有效订单-->
    <div class="path-row">
      <div class="path-item">
        <div class="value">
          <p>{{ boxData.validOrderAmount }}</p>
          <span>单</span>
        </div>
        <div class="label">
          有效订单数
        </div>
      </div>
      <div class="v-line is-reserve">
        <div class="line-text">
          有效订单占比<span>{{ pathData.p3 }}%</span>
        </div>
        <Icon type="md-arrow-dropleft" />
        <div class="line-inner">
          <span class="dot-1" />
          <span class="dot-2" />
          <span class="dot-3" />
          <span class="dot-4" />
        </div>
      </div>
      <div class="path-item">
        <div class="value">
          <p>{{ boxData.orderAmount }}</p>
          <span>单</span>
        </div>
        <div class="label">
          支付订单数
        </div>
      </div>
    </div>
    <div
      class="h-line-outer"
    >
      <!--向下竖线-->
      <div class="h-line">
        <div class="line-text">
          实收客单价<span>{{ pathData.p4 }}元</span>
        </div>
        <div class="line-inner" />
        <Icon type="md-arrow-dropdown" />
      </div>
    </div>
    <!--实收+客户数-->
    <div class="path-row">
      <div class="path-item">
        <div class="value">
          <p>{{ boxData.realAmount }}</p>
          <span>元</span>
        </div>
        <div class="label">
          总实付金额
        </div>
      </div>
      <div class="v-line">
        <div class="line-text">
          人均实付<span>{{ pathData.p5 }}元</span>
        </div>
        <div class="line-inner">
          <span class="dot-1" />
          <span class="dot-2" />
          <span class="dot-3" />
          <span class="dot-4" />
        </div>
        <Icon type="md-arrow-dropright" />
      </div>
      <div class="path-item">
        <div class="value">
          <p>{{ boxData.customerAmount }}</p>
          <span>人</span>
        </div>
        <div class="label">
          付款客户数
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { safeToFloat } from '@/libs/safe';
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      boxData: {},
      pathData: {
        // 小区->端口 = 一个小区平均端口
        p1: 0,
        // 端口->支付订单 = 一个端口平均订单
        p2: 0,
        // 支付订单->有效订单 = 有效订单占比
        p3: 0,
        // 有效订单->实收 = 客单价
        p4: 0,
        // 实收->客户数 = 人均实付
        p5: 0
      }
    };
  },
  created () {
    const bData = JSON.parse(JSON.stringify(this.model.totalAmountModel));
    this.boxData = bData;
    console.log(bData);
    const pData = this.pathData;
    if (bData.communityAmount) pData.p1 = safeToFloat(bData.portAmount / bData.communityAmount);
    if (bData.portAmount) pData.p2 = safeToFloat(bData.orderAmount / bData.portAmount);
    if (bData.orderAmount) pData.p3 = safeToFloat(bData.validOrderAmount / bData.orderAmount * 100);
    if (bData.validOrderAmount) pData.p4 = safeToFloat(bData.realAmount / bData.validOrderAmount);
    if (bData.customerAmount) pData.p5 = safeToFloat(bData.realAmount / bData.customerAmount);
  }
};
</script>
