<!--
    Created by 程雨喵'mac on 2024/5/13.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：几个关键参数
-->
<style lang="less">
.analysis-rate-data {
  //height: @containerGap + 530px;
  padding: @containerGap;
  background-color: @backColorStrong;
  border-radius: @borderRadiusMin;
  box-shadow: @shadowMin;
}
</style>

<template>
  <div class="analysis-rate-data">
    <div class="t1">
      经营数率
    </div>
    <div>
      <div
        id="rateDataLine"
        style="width:100%;height:201px"
      />
    </div>
  </div>
</template>

<script>
import { DATA_COLOR } from '../utils/colors';
const echarts = require('echarts');
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      boxItems: [
        { title: '端口使用率', key: 'portRateTrendModel', color: 'error', dataList: [] },
        { title: '支付订单客单价', key: 'payOrderPriceTrendModel', color: 'primary', dataList: [] }
        // { title: '退款占比', key: 'refundAmountPercentTrendModel', color: 'light', dataList: [] }
      ]
    };
  },
  mounted () {
    this.drawCharts();
  },
  methods: {
    drawCharts () {
      setTimeout(() => {
        const op1 = this.getLineOption();
        const temp = echarts.init(document.getElementById('rateDataLine'));
        temp.setOption(op1);
      }, 100);
    },
    getLineOption () {
      const titleArr = [];
      this.boxItems.forEach(v => {
        titleArr.push(v.title);
      });
      const series = [];
      this.boxItems.forEach((v, i) => {
        series.push({
          name: v.title,
          type: 'line',
          // stack: 'total',
          sampling: 'lttb',
          symbolSize: 2,
          smooth: true,
          itemStyle: {
            color: DATA_COLOR[v.color]
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            color: 'black',
            position: 'top',
            fontSize: 10
          },
          data: this.model[v.key].dataList
        });
      });
      return {
        title: {
          text: '',
          left: 'center'
        },
        legend: {
          data: titleArr,
          textStyle: {
            fontSize: 10
          }
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: 12
          },
          formatter: (params) => {
            // params 是一个数组，数组中包含每个系列的数据信息
            let result = '';
            const _this = this;
            params.forEach(function (item) {
              // item 是每一个系列的数据
              const marker = item.marker; // 标志图形
              const seriesName = item.seriesName; // 系列名称
              let value = item.value; // 数据值
              if (item.seriesName === '端口使用率') {
                const abs = _this.model.portRateTrendModel.arithmeticList[item.dataIndex];
                value = `有效订单(${abs.validOrderAmount}) ÷ 端口数(${abs.portAmount}) ÷ 天数(${abs.day}) × 100 = ${item.value}%`;
              } else if (item.seriesName === '支付订单客单价') {
                const abs = _this.model.payOrderPriceTrendModel.arithmeticList[item.dataIndex];
                value = `支付总金额(${abs.payAmount}) ÷ 支付订单数量(${abs.orderAmount}) = ${item.value}元`;
              }
              result += `${marker}${item.axisValueLabel}${seriesName}: ${value}<br/>`;
            });
            return result;
          }
        },
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'cross',
        //     label: {
        //       backgroundColor: '#6a7985'
        //     }
        //   }
        // },
        grid: {
          left: '1%',
          right: '3%',
          bottom: '1%',
          top: '15%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.model.rangeOption,
            axisLabel: {
              fontSize: 10 // 设置字体大小为16
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: series
      };
    }
  }
};
</script>
