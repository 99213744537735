/**
 * Created by 程雨喵'mac on 2024/5/6.
 * Copyright © 2024年 云柜-金星晖. All rights reserved.
 */
import md5 from 'md5';
import { formatDate, getNowTime, getMonthDayAmount, getDayDifferenceValue } from '@/libs/utils';
import { safeToFloat } from '@/libs/safe';

class BaseAnalysisItemModel {
  constructor (props) {
    this.isAnalysisItemModel = true;
  }

  // 子类实现
  _addOrder () {}
  // 子类实现
  _addDevice () {}
  // 子类实现
  _addCustomer () {}
}

class TotalAmountModel extends BaseAnalysisItemModel {
  constructor (rangeType = 1, rangeDate = '2024', rangeDay = 0) {
    super();
    this.rangeType = rangeType;
    this.rangeDate = rangeDate;
    this.rangeDay = rangeDay;
    // 有效物业公司总数（有设备的）
    this.propertyCompanyAmount = 0;
    this._propertyCompanyDic = {};
    // 有效小区总数（有设备的小区）
    this.communityAmount = 0;
    this._communityDic = {};
    // 设备总数
    this.deviceAmount = 0;
    // 端口总数
    this.portAmount = 0;
    // 已完成总订单数
    this.orderAmount = 0;
    // 有效订单数(有充电记录的)
    this.validOrderAmount = 0;
    // 总支付金额
    this.payAmount = 0;
    // 总退款金额
    this.refundAmount = 0;
    // 总实收金额
    this.realAmount = 0;
    // 总客户数
    this.customerAmount = 0;
    this._customerAmountDic = {};
    // 端口使用率
    this.portRate = 0;
  }

  setPortRate () {
    if (this.portAmount !== 0) {
      this.portRate = safeToFloat(this.validOrderAmount / this.portAmount / this.rangeDay * 100, 4);
    }
  }

  _addOrder (orderDic) {
    if (orderDic.realMoney) {
      this.validOrderAmount += 1;
    }
    if (orderDic.payMoney) {
      this.orderAmount += 1;
      this.payAmount += parseFloat(orderDic.payMoney);
    }
    if (orderDic.realMoney) {
      this.realAmount += parseFloat(orderDic.realMoney);
    }
    this.payAmount = parseFloat(this.payAmount.toFixed(2));
    this.realAmount = parseFloat(this.realAmount.toFixed(2));
    this.refundAmount = parseFloat((this.payAmount - this.realAmount).toFixed(2));
    this._customerAmountDic[orderDic.userTel] = 1;
    this.customerAmount = Object.keys(this._customerAmountDic).length;
  }

  _addDevice (deviceDic) {
    const t = (new Date(deviceDic.createTime)).getTime();
    let v = this.rangeDate;
    if (this.rangeType === 1) {
      v = v + '-12-31';
    } else {
      v = v + '-' + getMonthDayAmount(v + '-01');
    }
    const pT = (new Date(v + ' 23:59:59')).getTime();
    if (t < pT) {
      this.deviceAmount += 1;
      if (deviceDic.devicePortCount) {
        this.portAmount += deviceDic.devicePortCount;
      }
      if (deviceDic.propertyCompanyId) {
        this._propertyCompanyDic[deviceDic.propertyCompanyId] = 1;
      }
      if (deviceDic.communityId) {
        this._communityDic[deviceDic.communityId] = 1;
      }
      this.propertyCompanyAmount = Object.keys(this._propertyCompanyDic).length;
      this.communityAmount = Object.keys(this._communityDic).length;
    }
    console.log(v, t < pT, this.propertyCompanyAmount, this.communityAmount);
  }

  _addCustomer (customerDic) {
    const t = (new Date(customerDic.createTimeStr)).getTime();
    let v = this.rangeDate;
    if (this.rangeType === 1) {
      v = v + '-12-31';
    }
    const pT = (new Date(v + ' 23:59:59')).getTime();
    if (t < pT) {
      this.customerAmount += 1;
    }
  }
}

// 走势数据模型
class TrendModel {
  constructor (rangeType = 1, rangeDate = '2024', rangOption = []) {
    this.rangeType = rangeType;
    this.rangeDate = rangeDate;
    this.rangOption = rangOption;
    this.dataList = []; // 增量
    this.dataTotalList = []; // 总量
    this._utilDic = {}; // 一些数据要使用中转数据
    this._positionList = [];
    for (let i = 0; i < rangOption.length; i++) {
      this.dataList.push(0);
      this.dataTotalList.push(0);
      let num = i + 1;
      if (num < 10) {
        num = '0' + num;
      }
      this._positionList.push(rangeDate + '-' + num);
    }
  }

  setTotalList () {
    this.dataTotalList = [];
    for (let i = 0; i < this.dataList.length; i++) {
      let sum = this.dataList[i];
      if (i !== 0) {
        sum += this.dataTotalList[i - 1];
        sum = parseFloat(sum.toFixed(2));
      }
      this.dataTotalList[i] = sum;
    }
  }

  setRefundList (payList, realList) {
    for (let i = 0; i < payList.length; i++) {
      const p = payList[i];
      const r = realList[i];
      this.dataList[i] = parseFloat((p - r).toFixed(2));
    }
  }

  /**
   * @param validList 每月的有效订单(增量)
   * @param portTotalList 端口总数（总量）
   */
  setPortRate (validList, portTotalList) {
    // const d = (new Date());
    // const m = d.getMonth();
    this.arithmeticList = [];
    for (let i = 0; i < validList.length; i++) {
      const v = validList[i];
      const p = portTotalList[i];
      let day = 1;
      if (this.rangeType === 1) {
        // 按年
        let num = i + 1;
        if (num < 10) {
          num = '0' + num;
        }
        const date = this.rangeDate + '-' + num + '-01';
        day = getMonthDayAmount(date);
        // if (m === i) {
        //   // 本月
        //   day = d.getDate();
        // }
      }
      if (p) {
        this.dataList[i] = parseFloat(((v / p / day) * 100).toFixed(4));
        this.arithmeticList.push({
          validOrderAmount: v,
          portAmount: p,
          day: day
        });
      } else {
        this.dataList[i] = (0);
        this.arithmeticList.push({
          validOrderAmount: v,
          portAmount: 0,
          day: day
        });
      }
    }
  }

  setPayOrderPrice (payAmountList, payOrderList) {
    this.dataList = [];
    this.arithmeticList = [];
    for (let i = 0; i < payAmountList.length; i++) {
      const v = payAmountList[i];
      const p = payOrderList[i];
      if (p !== 0) {
        this.dataList.push(safeToFloat(v / p));
      } else {
        this.dataList.push(0);
      }
      this.arithmeticList.push({
        payAmount: v,
        orderAmount: p
      });
    }
  }

  setRefundAmountPercent (refundAmountList, payAmountList) {
    this.dataList = [];
    for (let i = 0; i < refundAmountList.length; i++) {
      const v = refundAmountList[i];
      const p = payAmountList[i];
      if (p !== 0) {
        this.dataList.push(safeToFloat(v / p * 100));
      } else {
        this.dataList.push(0);
      }
    }
  }

  _addValidOrder (orderDic) {
    const index = this._positionList.findIndex(v => orderDic.startTimeStr.includes(v));
    if (index !== -1 && orderDic.realMoney) {
      this.dataList[index] += 1;
      // this.dataList[index] = parseFloat(this.dataList[index].toFixed(2));
    }
  }

  _addPayOrder (orderDic) {
    const index = this._positionList.findIndex(v => orderDic.startTimeStr.includes(v));
    if (index !== -1 && orderDic.payMoney) {
      this.dataList[index] += 1;
      // this.dataList[index] = parseFloat(this.dataList[index].toFixed(2));
    }
  }

  _addOrder (orderDic, key) {
    const index = this._positionList.findIndex(v => orderDic.startTimeStr.includes(v));
    if (index !== -1) {
      this.dataList[index] += orderDic[key];
      this.dataList[index] = parseFloat(this.dataList[index].toFixed(2));
    }
  }

  _addOrderCustomer (orderDic) {
    const index = this._positionList.findIndex(v => orderDic.startTimeStr.includes(v));
    if (index !== -1) {
      const customer = this._utilDic[orderDic.userTel];
      if (!customer) {
        this._utilDic[orderDic.userTel] = 1;
        this.dataList[index] += 1;
      }
    }
  }

  _addDeviceAmount (deviceDic, key) {
    const index = this._positionList.findIndex(v => deviceDic.createTime.includes(v));
    if (index !== -1) {
      if (key) {
        this.dataList[index] += deviceDic[key];
      } else {
        this.dataList[index] += 1;
      }
      this.dataList[index] = parseFloat(this.dataList[index].toFixed(2));
    }
    if (this.rangeType === 2) {
      // 按月统计时需要在此时赋值
      const t = (new Date(deviceDic.createTime)).getTime();
      this._positionList.forEach((v, vIndex) => {
        const pT = (new Date(v + ' 23:59:59')).getTime();
        if (t < pT) {
          this.dataTotalList[vIndex] += key ? deviceDic[key] : 1;
        }
      });
    }
  }

  _addCustomer (customerDic) {
    const index = this._positionList.findIndex(v => customerDic.createTimeStr.includes(v));
    if (index !== -1) {
      // 增量数据
      this.dataList[index] += 1;
      this.dataList[index] = this.dataList[index];
    }
    if (this.rangeType === 2) {
      // 按月统计时需要在此时赋值
      const t = (new Date(customerDic.createTimeStr)).getTime();
      this._positionList.forEach((v, vIndex) => {
        const pT = (new Date(v + ' 23:59:59')).getTime();
        if (t < pT) {
          this.dataTotalList[vIndex] += 1;
        }
      });
    }
  }
}

// 城市排行
class CityRatingListModel {
  constructor (rangeType = 1, rangeDate = '2024', rangeDay = 0) {
    this.rangeType = rangeType;
    this.rangeDate = rangeDate;
    this.rangeDay = rangeDay;
    // 城市名称的md5为key，value为数据
    this._dataDic = {};
    this.dataList = [];
  }

  _getRowItem (cityName) {
    const cityMd5 = md5(cityName || '未知');
    if (!this._dataDic[cityMd5]) {
      this._dataDic[cityMd5] = {
        cityName: cityName || '未知',
        // 设备数量
        deviceAmount: 0,
        // 端口数量
        portAmount: 0,
        // 订单完成数量
        orderAmount: 0,
        // 有效订单数量
        validOrderAmount: 0,
        // 支付
        payAmount: 0,
        // 退款
        refundAmount: 0,
        // 实收
        realAmount: 0,
        // 支付客单价
        payOrderPrice: 0,
        // 端口使用率
        portRate: 0
      };
    }
    return this._dataDic[cityMd5];
  }

  addOrder (orderDic, isCommunity = false) {
    let cName = isCommunity ? orderDic.blockName : orderDic.cityName;
    if (cName === '市辖区') {
      cName = orderDic.provinceName;
    }
    const item = this._getRowItem(cName);
    item.orderAmount += 1;
    if (orderDic.realMoney) {
      item.validOrderAmount += 1;
    }
    if (orderDic.payMoney) {
      item.payAmount += safeToFloat(orderDic.payMoney);
    }
    if (orderDic.realMoney) {
      item.realAmount += safeToFloat(orderDic.realMoney);
    }
    item.payAmount = safeToFloat(item.payAmount);
    item.realAmount = safeToFloat(item.realAmount);
    item.refundAmount = safeToFloat((item.payAmount - item.realAmount));
    item.payOrderPrice = safeToFloat((item.payAmount / item.orderAmount));
    if (item.portAmount !== 0) {
      item.portRate = safeToFloat((item.validOrderAmount / item.portAmount / this.rangeDay) * 100, 4);
    }
  }

  addDevice (deviceDic, isCommunity = false) {
    let cName = isCommunity ? deviceDic.communityName : deviceDic.cityName;
    if (cName === '市辖区') {
      cName = deviceDic.provinceName;
    }
    const item = this._getRowItem(cName);
    const t = (new Date(deviceDic.createTime)).getTime();
    let v = this.rangeDate;
    if (this.rangeType === 1) {
      v = v + '-12-31';
    } else {
      v = v + '-' + getMonthDayAmount(v + '-01');
    }
    const pT = (new Date(v + ' 23:59:59')).getTime();
    if (t < pT) {
      item.deviceAmount += 1;
      if (deviceDic.devicePortCount) {
        item.portAmount += deviceDic.devicePortCount;
      }
    }
  }

  transferToList () {
    console.log(this._dataDic);
    this.dataList = [];
    // 计算端口使用率
    for (const key in this._dataDic) {
      const value = this._dataDic[key];
      // value.portRate = 暂时无法计算
      this.dataList.push(value);
    }
  }
}

class TimeLineModel {
  constructor (rangeType = 1, rangeDate = '2024', rangOption = []) {
    this.rangeType = rangeType;
    this.rangeDate = rangeDate;
    this.rangOption = rangOption;
    this._startOrgList = {};
    this._endOrgList = {};
    this.startDataList = []; // 最终输出
    this.endDataList = []; // 最终输出
    this.times = ['0~2点', '2~4点', '4~6点', '6~8点', '8~10点', '10~12点', '12~14点', '14~16点', '16~18点', '18~20点', '20~22点', '22~24点'];
    this._positionArr = [];
    this._timeStartArr = ['00:00:00', '02:00:00', '04:00:00', '06:00:00', '08:00:00', '10:00:00', '12:00:00', '14:00:00', '16:00:00', '18:00:00', '20:00:00', '22:00:00'];
    this._timeArr = ['02:00:00', '04:00:00', '06:00:00', '08:00:00', '10:00:00', '12:00:00', '14:00:00', '16:00:00', '18:00:00', '20:00:00', '22:00:00', '23:59:59'];
    for (let i = 0; i < rangOption.length; i++) {
      for (let j = 0; j < this._timeStartArr.length; j++) {
        this._startOrgList[i + '-' + j] = 0;
        this._endOrgList[i + '-' + j] = 0;
        // j-0: 0~6点, j-1: 6~12点, j-2: 12~18点, j-3: 18~24点
        this._positionArr.push([]);
      }
    }
  }

  _addOrder (orderDic) {
    // 开始时间
    const s = orderDic.startTimeStr;
    // 实际结束时间
    const e = orderDic.realEndTimeStr;
    // 结束时间小于开始时间就直接舍弃
    const sss = (new Date(s)).getTime();
    const eee = (new Date(e)).getTime();
    if (eee < sss) {
      return;
    }
    const fun = (targetTime) => {
      let i = 0;
      let j = 0;
      if (targetTime) {
        const d = new Date(targetTime);
        const dTime = d.getTime();
        if (this.rangeType === 1) {
          // 按年
          i = d.getMonth();
        } else {
          i = d.getDate() - 1;
        }
        for (let ii = 0; ii < this._timeArr.length; ii++) {
          const ttt = this._timeArr[ii];
          const sss = this._timeStartArr[ii];
          const tttTime = (new Date(formatDate(d) + ' ' + ttt)).getTime();
          const sssTime = (new Date(formatDate(d) + ' ' + sss)).getTime();
          if (sssTime <= dTime && tttTime >= dTime) {
            j = ii;
            break;
          }
        }
      }
      return { i: i, j: j };
    };
    const ssss = fun(s);
    this._startOrgList[ssss.i + '-' + ssss.j] += 1;
    const eeee = fun(e);
    this._endOrgList[eeee.i + '-' + eeee.j] += 1;
  }

  transferToList () {
    this.startDataList = [];
    this.endDataList = [];
    for (let i = 0; i < this.rangOption.length; i++) {
      for (let j = 0; j < this._timeStartArr.length; j++) {
        this.startDataList.push([i, j, this._startOrgList[i + '-' + j]]);
        this.endDataList.push([i, j, this._endOrgList[i + '-' + j]]);
      }
    }
  }
}

class DataAnalysisModel {
  constructor (rangeType = 1, rangeDate = '2024') {
    /**
     * 1: 按年
     * 2: 按月
     * @type {number}
     */
    this.rangeType = rangeType;
    /**
     * 按年时：值为年份(例:2024)
     * 按月时：值为年月(例:2024-01)
     * @type {string}
     */
    this.rangeDate = rangeDate;
    this.rangeDateStr = '';
    if (this.rangeType === 1) {
      this.rangeDateStr = this.rangeDate + '全年';
    } else {
      const arr = this.rangeDate.split('-');
      this.rangeDateStr = `${arr[0]}年${arr[1]}月`;
    }
    // 统计中的总天数
    this.rangeDay = 0;
    this.rangeOption = [];
    if (rangeType === 1) {
      // 按年
      this.rangeOption = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
      const nowYear = (new Date()).getFullYear();
      if (rangeDate === (nowYear + '')) {
        this.rangeDay = getDayDifferenceValue(rangeDate + '-01-01', getNowTime());
      } else {
        this.rangeDay = parseInt(rangeDate) % 4 === 0 ? 366 : 365;
      }
    } else {
      // 按月
      const monthDay = getMonthDayAmount(rangeDate + '-01');
      this.rangeDay = monthDay;
      for (let i = 1; i <= monthDay; i++) {
        this.rangeOption.push(i + '日');
      }
    }
    this.createTime = getNowTime(); // 数据生成时间
    this.serviceName = '';
    this.userId = '';
    // 数量集合
    this.totalAmountModel = new TotalAmountModel(rangeType, this.rangeDate, this.rangeDay); // TotalAmountModel实例
    // 走势-客户量
    this.customerTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 走势-充电桩数量
    this.deviceTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 走势-端口数量
    this.portTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 走势-支付
    this.payAmountTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 走势-退款
    this.refundAmountTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 走势-实收
    this.realAmountTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 走势-有效订单
    this.validOrderTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 走势-支付订单
    this.payOrderTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 走势-实际充电时长
    this.realChargeDurationTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 走势-端口使用率（无全量）
    this.portRateTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 走势-客单价走势（无全量）
    this.payOrderPriceTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 走势-退款金额占比（无全量）
    this.refundAmountPercentTrendModel = new TrendModel(rangeType, this.rangeDate, this.rangeOption); // TrendModel 实例
    // 城市排行
    this.cityRatingList = new CityRatingListModel(rangeType, this.rangeDate, this.rangeDay); // CityRatingListModel 实例
    // 小区排行
    this.communityRatingList = new CityRatingListModel(rangeType, this.rangeDate, this.rangeDay); // CityRatingListModel 实例
    // 充电时段
    this.realChargeTimeLineModel = new TimeLineModel(rangeType, this.rangeDate, this.rangeOption); // TimeLineModel
  }

  startAnalysis (dic) {
    const {
      deviceList,
      orderList
      // customerList
    } = dic;
    return new Promise(resolve => {
      // 设备遍历
      const deviceLen = deviceList.length;
      for (let i = 0; i < deviceLen; i++) {
        const deviceDic = deviceList[i];
        this.totalAmountModel._addDevice(deviceDic);
        this.deviceTrendModel._addDeviceAmount(deviceDic);
        this.portTrendModel._addDeviceAmount(deviceDic, 'devicePortCount');
        this.cityRatingList.addDevice(deviceDic);
        this.communityRatingList.addDevice(deviceDic, true);
      }
      // // 客户遍历
      // const customerLen = customerList.length;
      // for (let i = 0; i < customerLen; i++) {
      //   const customerDic = customerList[i];
      //   this.totalAmountModel._addCustomer(customerDic);
      //   this.customerTrendModel._addCustomer(customerDic);
      // }
      // 订单遍历
      const orderLen = orderList.length;
      for (let i = 0; i < orderLen; i++) {
        const orderDic = orderList[i];
        this.totalAmountModel._addOrder(orderDic);
        this.validOrderTrendModel._addValidOrder(orderDic);
        this.payOrderTrendModel._addPayOrder(orderDic);
        this.payAmountTrendModel._addOrder(orderDic, 'payMoney');
        this.realAmountTrendModel._addOrder(orderDic, 'realMoney');
        this.realChargeDurationTrendModel._addOrder(orderDic, 'realChargeDuration');
        this.cityRatingList.addOrder(orderDic);
        this.communityRatingList.addOrder(orderDic, true);
        this.realChargeTimeLineModel._addOrder(orderDic);
        this.customerTrendModel._addOrderCustomer(orderDic);
      }

      // 退款走势计算(支付金额 - 实付金额)
      this.refundAmountTrendModel.setRefundList(this.payAmountTrendModel.dataList, this.realAmountTrendModel.dataList);

      this.payAmountTrendModel.setTotalList();
      this.realAmountTrendModel.setTotalList();
      this.refundAmountTrendModel.setTotalList();
      this.validOrderTrendModel.setTotalList();
      this.payOrderTrendModel.setTotalList();

      if (this.rangeType === 1) {
        this.deviceTrendModel.setTotalList();
        this.portTrendModel.setTotalList();
        this.customerTrendModel.setTotalList();
      }

      this.realChargeDurationTrendModel.setTotalList();

      // 端口使用率走势(!!!必须得在有效订单走势和端口数量走势后算)
      this.portRateTrendModel.setPortRate(this.validOrderTrendModel.dataList, this.portTrendModel.dataTotalList);
      this.payOrderPriceTrendModel.setPayOrderPrice(this.payAmountTrendModel.dataList, this.payOrderTrendModel.dataList);
      this.refundAmountPercentTrendModel.setRefundAmountPercent(this.refundAmountTrendModel.dataList, this.payAmountTrendModel.dataList);

      this.totalAmountModel.setPortRate();
      this.cityRatingList.transferToList();
      this.communityRatingList.transferToList();
      this.realChargeTimeLineModel.transferToList();

      // 清理数据
      this.totalAmountModel._customerAmountDic = null;
      this.totalAmountModel._propertyCompanyDic = null;
      this.cityRatingList._dataDic = null;
      this.communityRatingList._dataDic = null;
      resolve(this);
    });
  }
}

export default DataAnalysisModel;
