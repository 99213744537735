<!--
    Created by 程雨喵'mac on 2024/5/10.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：各项走势
-->
<style lang="less">
.analysis-grow-up {
  margin-top: @containerGap;
  height: @containerGap + 530px;
  padding: @containerGap;
  background-color: @backColorStrong;
  border-radius: @borderRadiusMin;
  box-shadow: @shadowMin;
  .grow-up-box {
    margin-top: @containerGap;
    font-size: 12px;
    .grow-up-row {
      &+.grow-up-row {
        padding-top: @containerGap;
        margin-top: @containerGap;
        border-top: 1px solid @lineColorLight;
      }
      .grow-up-tab {
        margin-bottom: @containerGap;
        display: flex;
        .grow-up-tab-item {
          padding: 0 10px;
          line-height: 20px;
          border-radius: @borderRadiusMin;
          &.is-current {
            background-color: @themeColor;
            color: white;
            font-weight: 600;
          }
        }
      }
      .grow-up-chart {
        height: 225px;
      }
    }
  }
}
</style>

<template>
  <div class="analysis-grow-up">
    <div class="t1 display-flex display-flex-h-between">
      数据走势
      <div class="top-tab">
        <div
          v-for="(item, i) in tab.items"
          :key="i"
          class="tab-item"
          :class="i === tab.index ? 'is-current' : ''"
          @click.stop="_clickTab(i)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="grow-up-box">
      <div class="grow-up-row">
        <div
          id="growUp-0"
          style="width:100%;height:225px"
          class="grow-up-chart"
        />
      </div>
      <div class="grow-up-row">
        <div
          id="growUp-1"
          style="width:100%;height:225px"
          class="grow-up-chart"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DATA_COLOR } from '../utils/colors';
const echarts = require('echarts');
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      tab: {
        items: ['增量', '全量'],
        index: 0
      },
      boxItems: [
        [
          { label: '端口数量', key: 'portTrendModel' },
          // { label: '端口使用率', key: 'portRateTrendModel' },
          { label: '充电桩数量', key: 'deviceTrendModel' },
          { label: '支付订单', key: 'payOrderTrendModel' },
          { label: '有效订单', key: 'validOrderTrendModel' }
          // { label: '端口数量', key: 'portTrendModel' }
        ],
        [
          { label: '实收金额', key: 'realAmountTrendModel' },
          { label: '支付金额', key: 'payAmountTrendModel' },
          { label: '退款金额', key: 'refundAmountTrendModel' },
          { label: '付款客户量', key: 'customerTrendModel' }
        ]
      ]
    };
  },
  created () {
    this.$nextTick(() => {
      this.initData();
    });
  },
  methods: {
    _clickTab (i) {
      if (i !== this.tab.index) {
        this.tab.index = i;
        this.initData();
      }
    },
    initData () {
      const b = this.boxItems;
      for (let i = 0; i < b.length; i++) {
        const items = b[i];
        const titleArr = [];
        const dataArr = [];
        items.forEach(v => {
          titleArr.push(v.label);
          dataArr.push(this.tab.index === 0 ? this.model[v.key].dataList : this.model[v.key].dataTotalList);
        });
        const op = this.getGrowUpOption(titleArr, dataArr, i);
        const temp = echarts.init(document.getElementById('growUp-' + i));
        temp.setOption(op);
      }
    },
    getGrowUpOption (titleArr, dataArr, color = 0) {
      const series = [];
      titleArr.forEach((v, i) => {
        console.log(i);
        series.push({
          name: v,
          type: 'line',
          sampling: 'lttb',
          symbolSize: 6,
          smooth: true,
          itemStyle: {
            color: !color ? DATA_COLOR.primary : DATA_COLOR.adorn
          },
          areaStyle: {
            // 对应右/下/左/上四个方位，而0 0 0 1则代表渐变色从正上方开始
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: !color ? DATA_COLOR.primaryAlpha : DATA_COLOR.adornAlpha },
              { offset: 1, color: !color ? DATA_COLOR.primaryAlphaDeep : DATA_COLOR.adornAlphaDeep }
            ])
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            color: 'black',
            position: 'top',
            fontSize: 10
          },
          data: dataArr[i]
        });
      });
      return {
        title: {
          text: '',
          left: 'center'
        },
        legend: {
          selectedMode: 'single',
          data: titleArr,
          textStyle: {
            fontSize: 10
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '1%',
          right: '3%',
          bottom: '1%',
          top: '20%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.model.rangeOption,
            axisLabel: {
              fontSize: 10 // 设置字体大小为16
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: series
      };
    }
  }
};
</script>
